import React from 'react';
import { Link } from 'gatsby';
import { Trans } from '@lingui/macro';
import Image from 'components/atoms/Image/Image';

const TemporaryFractalHighlights = () => (
  <div className="content-block">
    <Image className="content-block__image" name="home-page/fractal-product" />
    <div className="content-block__info">
      <h3 className="product__title">
        Fractal Cloud
    </h3>
      <p className="product__subtitle">
        <Trans>Your Internal Developer Platform. Ready Today.</Trans>
      </p>
      <ul className="product__highlight">
        <li className="product__highlight-item">
          <Trans>PaaS and IaaS Services for all main Cloud Vendors</Trans>
        </li>
        <li className="product__highlight-item">
          <Trans>
            Any Kubernetes distro in
            public, private, and hybrid setups
        </Trans>
        </li>
        <li className="product__highlight-item">
          <Trans>Integrate your existing IaC solutions as custom components</Trans>
        </li>
        <li className="product__highlight-item">
          <Trans>
            Automated scanning and onboarding of existing infrastructure
        </Trans>
        </li>
        <li className="product__highlight-item">
          <Trans>RBAC and granular access control</Trans>
        </li>
        <li className="product__highlight-item">
          <Trans>
            Complete visibility and governance
            while delegating infrastructure ownership to the Development Teams
        </Trans>
        </li>
        <li className="product__highlight-item">
          <Trans>Flexibility and Extensibility thanks to the adoption of open standards</Trans>
        </li>
      </ul>

      <Link to="/fractal" className="button--cta">
        <Trans>Read More</Trans>
      </Link>
    </div>
  </div>
);

export default TemporaryFractalHighlights;