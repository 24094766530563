import './Image.scss';
import React, { useEffect, useRef, useState, FC, ReactNode } from 'react';
import classNames from 'classnames';

type ImageProps = {
  name: string,
  type?: 'svg' | 'png',
  category?: string;
  className?: string;
};

const Image: FC<ImageProps> = ({ name, type = 'svg', category, className = '', ...rest }) => {
  const ImportedImageRef = useRef<HTMLImageElement & { default: string; }>();
  const [loading, setLoading] = useState(false);

  const path = ['images/'];
  if (typeof category === 'string') {
    path.push(`${category}/`);
  }

  if (typeof name === 'string') {
    path.push(`${name}`);
  }

  if (typeof type === 'string') {
    path.push(`.${type}`);
  }
  const finalPath = path.join('');

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const importImage = async () => {
      try {
        ImportedImageRef.current = await import(`assets/${finalPath}`);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };
    importImage();

    return () => {
      mounted = false;
    };
  }, [category, name, type]);

  const spanClassName = classNames(className);
  className = `svg-image ${name.replace('/', '-')}`;

  return (
    <i
      className={classNames('svg-image-wrapper', spanClassName, {
        'svg-image-wrapper--loaded': !loading,
      })}
    >
      {!loading && typeof ImportedImageRef !== 'undefined' && typeof ImportedImageRef.current !== 'undefined' && (
        <img src={ImportedImageRef.current.default} alt="" className={className} {...rest} />
      )}
    </i>
  );
};

export default Image;

